'use client';

import type * as nav from '@repo-ui/nav';
import { mainLayoutConstants as LAYOUT } from '@/layout/MainLayout/main-layout-constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import type { SxProps } from '@mui/material';
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import SideMenuItem from './SideMenuItem';
import React, { useMemo } from 'react';

const rootSx: SxProps = {
  pl: 2,
  '&.SideSectionMenu-expand': {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },

  '&.SideSectionMenu-active.SideSectionMenu-expand': {
    borderTop: `1px solid ${LAYOUT.sidemenu.dividerColor}`,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },

  '&:hover': {
    backgroundColor: LAYOUT.sidemenu.bgColorHoverBack,
  },

  '& .MuiListItemText-root': {
    color: LAYOUT.sidemenu.fgColor,
  },

  '& .SideSectionMenu-indicator.MuiSvgIcon-root': {
    color: 'rgba(255,255,255,0.5)',
  },

  '& .SideSectionMenu-icon': {
    minWidth: 32,
    fontSize: '1.3rem',
    color: LAYOUT.sidemenu.iconColor, // '#e1f5fe',
  },

  '&.SideSectionMenu-expand .SideSectionMenu-icon .MuiSvgIcon-root': {
    color: '#4fc3f7',
  },

  '& .SideSectionMenu-title': {
    fontWeight: 400,
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
      color: '#e1f5fe',
    },
  },

  '&.SideSectionMenu-active': {
    '& .SideSectionMenu-indicator.MuiSvgIcon-root': {
      color: LAYOUT.sidemenu.iconColorActive,
    },
    '& .SideSectionMenu-title': {
      fontWeight: 600,
      '& .MuiTypography-root': {
        color: '#4fc3f7',
      },
    },
  },
};

const collapseRootSx: SxProps = {
  boxSizing: 'border-box',
  mr: 0,
  '&.SideSectionMenu-expand': {
    background: 'rgba(0,0,0,0.05)',
  },
};

interface Props {
  section: nav.ISection;
  expanded: boolean;
  currentHref?: string;
  navManager: nav.MenuManager;
  onClickSection?: React.MouseEventHandler;
  onClickLink?: React.MouseEventHandler;
}

export default function SideSectionMenu(props: Props) {
  const {
    navManager,
    section,
    onClickSection: onSectionClick,
    expanded = false,
    currentHref,
    onClickLink: onLinkClick,
  } = props;

  const active = useMemo(
    () => navManager.isSectionPathMatched(section, currentHref),
    [navManager, section, currentHref],
  );

  return (
    <>
      <ListItemButton
        onClick={onSectionClick}
        className={clsx('SideSectionMenu-root', {
          'SideSectionMenu-active': active,
          'SideSectionMenu-expand': expanded,
        })}
        sx={rootSx}
      >
        {section.icon && (
          <ListItemIcon className="SideSectionMenu-icon">{section.icon}</ListItemIcon>
        )}
        <ListItemText primary={section.title} className="SideSectionMenu-title" />
        {expanded ? (
          <KeyboardArrowUpIcon className="SideSectionMenu-indicator" />
        ) : (
          <KeyboardArrowDownIcon className="SideSectionMenu-indicator" />
        )}
      </ListItemButton>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className={clsx({
          'SideSectionMenu-expand': expanded,
        })}
        sx={collapseRootSx}
      >
        <List disablePadding>
          {section.submenus.map((menu) => {
            if (menu.type === 'divider') {
              return <Divider key={menu.id} />;
            }
            return (
              <SideMenuItem
                key={menu.id}
                menu={menu}
                sx={{
                  '& a': {
                    pl: 5,
                  },
                }}
                onLinkClick={onLinkClick}
                active={navManager.isMenuPathMatched(menu, currentHref)}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
