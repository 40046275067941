'use client';

import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {
  Box,
  ButtonBase,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { LoginButton } from '@repo-ui/components/LoginButton';
import { useLoginUser } from '@repo-ui/store/useAuthStore';
import { useDebounceEffect } from 'ahooks';
import clsx from 'clsx';
import { useLocale } from 'next-intl';
import NextImage from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useRef, useState } from 'react';
import { mainLayoutConstants as LAYOUT } from '../../main-layout-constants';
import { Submenu } from './Submenu';
import { TopMenu } from './TopMenu';

interface AppBarProps extends MuiAppBarProps {
  shift: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'shift',
})<AppBarProps>(({ theme, shift }) => ({
  zIndex: theme.zIndex.drawer + (shift ? 1 : -1),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: '#fff',
  color: '#FFF',
  ...(shift && {
    width: `calc(100% - ${LAYOUT.sidemenu.width}px)`,
    marginLeft: LAYOUT.sidemenu.width,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!shift && {
    '& .MuiToolbar-root': {
      paddingLeft: theme.spacing(3.5),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(3),
      },
    },
  }),
}));

interface Props {
  title?: string;
  className?: string;
  isSidebarOpen: boolean;
  hideLoginButton?: boolean;
  isSmallScreen: boolean;
  onClickMenuButton?: React.MouseEventHandler;
}

export default function MainLayoutTopbar(props: Props) {
  const {
    className,
    title,
    isSmallScreen,
    isSidebarOpen,
    hideLoginButton = false,
    onClickMenuButton,
  } = props;
  const locale = useLocale();
  const loginUser = useLoginUser();
  const profile = loginUser.profile;
  const pathname = usePathname();
  const rootRef = useRef<HTMLElement | null>(null);
  const theme = useTheme();
  const lgOrDown = useMediaQuery(theme.breakpoints.down('lg'));
  const shift = Boolean(isSidebarOpen && !lgOrDown);
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(-1);
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [debouncedSubmenuVisible, setDebouncedSubmenuVisible] = useState(false);
  const [menuLeft, setMenuLeft] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(menuAnchorEl);

  useDebounceEffect(
    () => {
      setDebouncedSubmenuVisible(submenuVisible);
    },
    [submenuVisible],
    { wait: 100 },
  );

  const onChangeMenuMeasure = () => {
    const root = rootRef.current;
    if (!root) return;
    const topMenuBox = root.querySelector('.TopMenu-menuBox') as HTMLElement;
    if (!topMenuBox) {
      console.warn('topMenuBox not exists');
      return;
    }
    setMenuLeft(calcAbsOffset(topMenuBox).x + -220);
  };

  const fixSubmenuLeft = () => {
    const root = rootRef.current;
    if (!root) return;
    const topMenuBox = root.querySelector('.TopMenu-menuBox') as HTMLElement;
    const submenuBox = root.querySelector('.Submenu-root .Submenu-menuBox') as HTMLElement;
    if (!topMenuBox) {
      console.warn('topMenuBox not exists');
      return;
    }
    if (!submenuBox) {
      console.warn('submenuBox not exists');
      return;
    }
    // console.log("XXXX submenu left=", calcAbsOffset(topMenuBox).x);
    submenuBox.style.left = calcAbsOffset(topMenuBox).x + '-220px';
  };

  // 서브메뉴 표시/감추기 - 표시할때는 즉시 표시, 감출때는 200ms후에 감추기
  const showSubmenu = (visible: boolean) => {
    if (visible) {
      setSubmenuVisible(true);
      setDebouncedSubmenuVisible(true);
    } else {
      setSubmenuVisible(false);
    }
  };

  const handleMenuMouseEnter = (event: React.MouseEvent, menuIndex: number) => {
    setActiveMenuIndex(menuIndex);
    showSubmenu(true);
  };

  const handleMenuMouseLeave = (event: React.MouseEvent) => {
    showSubmenu(false);
  };

  const handleSubmenuMouseEnter = (event: React.MouseEvent) => {
    fixSubmenuLeft();
    showSubmenu(true);
  };

  const handleSubmenuMouseLeave = (event: React.MouseEvent) => {
    showSubmenu(false);
    // showSubmenu(true);
  };

  const handleClickLanguage = (event: React.MouseEvent) => {
    setMenuAnchorEl(event.target as HTMLElement);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <AppBar
      ref={rootRef}
      className={clsx('MainLayoutTopbar-root', className)}
      position="fixed"
      shift={shift}
      elevation={1}
      sx={{
        background: '#26282A',
        color: '#222',
        boxShadow: 'none',
        px: { xs: '0px', md: '36px' },
        height: 64,
        '& .MuiToolbar-root': {
          px: 0,
        },
      }}
    >
      <Toolbar>
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isSmallScreen && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', ml: 1 }}>
                <IconButton
                  onClick={onClickMenuButton}
                  aria-label="open drawer"
                  sx={{
                    color: '#fff',
                  }}
                >
                  {isSidebarOpen ? (
                    <MenuOpenIcon htmlColor="#fff" />
                  ) : (
                    <MenuIcon htmlColor="#fff" />
                  )}
                </IconButton>
                <Box
                  className={'TopMenu-logo'}
                  sx={{
                    ml: 2,
                    mt: 1,
                    '& a': {
                      width: 174,
                    },
                  }}
                >
                  <Link href="/" aria-label="move to home">
                    <NextImage
                      src="/_static/images/ocode_logo_white.png"
                      alt=""
                      width={168}
                      height={52}
                      priority
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                className="MainLayoutTopbar-toolBox"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mr: 1,
                }}
              >
                <ButtonBase
                  sx={{
                    p: 1,
                    '& .MuiSvgIcon-root': {
                      color: '#76787A',
                    },
                  }}
                >
                  <LanguageIcon onClick={handleClickLanguage} />
                </ButtonBase>

                <Menu
                  anchorEl={menuAnchorEl}
                  open={menuOpened}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '15px',
                    },
                  }}
                >
                  <Box>
                    <MenuItem>
                      <ListItemText>한국어</ListItemText>
                    </MenuItem>
                    <MenuItem>
                      <ListItemText>English</ListItemText>
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>

              {/* <Typography sx={{ ml: 1, fontWeight: 600, flex: 1 }} variant="h4" component="div">
                {title}
              </Typography> */}
            </Box>
          )}
          {!isSmallScreen && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 25,
              }}
            >
              <TopMenu
                className="MainLayoutTopbar-topMenu"
                activeMenuIndex={activeMenuIndex}
                visibleIndicator={submenuVisible}
                pathname={pathname}
                onMenuMouseEnter={handleMenuMouseEnter}
                onMenuMouseLeave={handleMenuMouseLeave}
                onChangeMeasure={onChangeMenuMeasure}
              />
              <Box
                className="MainLayoutTopbar-toolBox"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '& .MuiSvgIcon-root': {
                    color: '#76787A',
                  },
                }}
              >
                {!hideLoginButton && <LoginButton />}

                <ButtonBase onClick={handleClickLanguage}>
                  <LanguageIcon />
                </ButtonBase>

                <Menu
                  anchorEl={menuAnchorEl}
                  open={menuOpened}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      px: 1,
                    },
                  }}
                >
                  <MenuItem>
                    <ListItemText>한국어</ListItemText>
                  </MenuItem>
                  <MenuItem>
                    <ListItemText>English</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Box>
      </Toolbar>

      {!isSmallScreen && debouncedSubmenuVisible && (
        <Submenu
          pathname={pathname}
          onMouseEnter={handleSubmenuMouseEnter}
          onMouseLeave={handleSubmenuMouseLeave}
          menuLeft={menuLeft}
          locale={locale}
        />
      )}
    </AppBar>
  );
}

function calcAbsOffset(element: HTMLElement): { x: number; y: number } {
  let el: HTMLElement | null = element;
  let x = 0;
  let y = 0;
  do {
    x += el.offsetLeft;
    y += el.offsetTop;
    el = el.offsetParent as HTMLElement | null;
  } while (el);
  return { x, y };
}
