import { CustomDialog } from '../../components/CustomDialog';
import { CustomDialogTitle } from '@repo-shared-base/components/CustomDialogTitle';
import { ClipboardButton } from '../../components/ClipboardButton';
import { MdOrUp } from '@ocodelib/ui-common';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OpenSourceCate } from '@ocode/domain';
import type { OpenSourceCateKey } from '@ocode/domain';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { joinUrl } from '@ocodelib/util/http';
import { config } from '../../config';

export interface OpenSourceDialogProps {
  open: boolean;
  cate?: OpenSourceCateKey;
  onClose: () => void;
}

const { apiBaseURL = '', baseURL, datiBaseURL } = config;

export const openSourceUrls = {
  /**
   * 오픈 소스 보기 URL
   */
  view: (cate: OpenSourceCateKey): string => {
    return joinUrl(datiBaseURL, `/d_/opensource-license/${cate.toLowerCase()}.html`);
    // return apiUrlOf(`/p/m/open-source-license/${cate}.html`)
  },
};

const CATES: OpenSourceCateKey[] = ['WEB', 'SERVER', 'HWPC'];

export default function OpenSourceDialog(props: OpenSourceDialogProps) {
  const { open, onClose } = props;
  const [cate, setCate] = useState<OpenSourceCateKey>(props.cate ?? 'WEB');

  const handleDialogClose = () => {
    onClose?.();
  };

  const url = openSourceUrls.view(cate);
  return (
    <CustomDialog
      className="OpenSourceLicenseViewDialog-root"
      open={open}
      maxWidth="md"
      fullWidth
      onClose={onClose}
    >
      <CustomDialogTitle
        onClose={handleDialogClose}
        sx={{
          pt: 0,
          mt: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <MdOrUp>
            <Typography variant="body2">오픈소스 라이선스</Typography>
          </MdOrUp>
          <Tabs
            value={cate}
            onChange={(_, i) => {
              setCate(i);
            }}
          >
            {CATES.map((it) => (
              <Tab key={it} label={OpenSourceCate[it]} value={it} />
            ))}
          </Tabs>
          {/* dummy */}
          <Box flex={0} />
        </Box>
      </CustomDialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Box
          sx={{
            height: 'calc(100vh - 178px)',
            display: 'flex',
            flexDirection: 'column',
            '& .IframeDialog-iframe': {
              width: '100%',
              flex: 1,
              border: 'none',
            },
          }}
        >
          <iframe className="IframeDialog-iframe" src={url} title="dialog content frame" />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Box>
          <ClipboardButton text="주소복사" />
          <Tooltip title="새창">
            <IconButton
              aria-label="OpenInNew"
              href={url}
              target="_blank"
              style={{ marginLeft: 8 }}
              size="large"
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Button onClick={handleDialogClose}>닫기</Button>
        </Box>
      </DialogActions>
    </CustomDialog>
  );
}
