import type { ContainerProps } from '@mui/material';
import { Box, Container } from '@mui/material';

export type CanDisableContainerProps = {
  disabled?: boolean;
} & ContainerProps;

export default function CanDisableContainer(props: CanDisableContainerProps) {
  const { sx, disabled = false, className, style, children, ...restProps } = props;

  if (disabled) {
    return (
      <Box className={className} style={style} sx={sx}>
        {children}
      </Box>
    );
  }
  return (
    <Container className={className} style={style} disableGutters sx={sx} {...restProps}>
      {children}
    </Container>
  );
}
