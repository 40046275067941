'use client';

import { addLocalePrefix } from '@/layout/DevLayout/components/sidebar/addLocalePrefix';
import { Box, MenuList, MenuItem as MuiMenuItem, type MenuItemProps } from '@mui/material';
import NextLink from 'next/link';
import React from 'react';
import { SUBMENUS_ARRAY } from '../../main-layout-top-menus';
import { MENU_BAR_WIDTH, isCurrentMenu } from './util';

export function Submenu(props: {
  menuLeft: number;
  pathname: string;
  onMouseEnter: React.MouseEventHandler;
  onMouseLeave: React.MouseEventHandler;
  locale?: string;
}) {
  const { locale, menuLeft, pathname, onMouseEnter, onMouseLeave } = props;

  return (
    <Box
      className="Submenu-root"
      sx={{
        position: 'relative',
        display: 'block',
      }}
    >
      <Box
        className="Submenu-menuBox"
        sx={{
          position: 'absolute',
          display: 'block',
          width: MENU_BAR_WIDTH,
          m: 0,
          borderRadius: '0 0 15px 15px',
          boxShadow: `0 1px 2px rgba(0,0,0,0.03),
                      0 2px 4px rgba(0, 0, 0, 0.03),
                      0 4px 8px rgba(0,0,0,0.03),
                      0 8px 16px rgba(0,0,0,0.03),
                      0 16px 32px rgba(0,0,0,0.03),
                      0 32px 64px rgba(0,0,0,0.03)`,
          overflow: 'hidden',
          // borderBottom: "1px solid #C6D0DF",
          background: 'rgba(255, 255, 255, 0.5)',
          left: menuLeft + 160,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            pb: 2,
            '& .MuiList-root': {
              position: 'relative',
              flexBasis: `${100 / SUBMENUS_ARRAY.length}%`,
              '& .MuiMenuItem-root': {
                justifyContent: 'center',
                p: 1,
                mt: 2,
                color: '#26282A',
                fontSize: '1rem',
                fontWeight: 500,
                '&.Mui-active': {
                  color: 'primary.main',
                },
              },
            },
          }}
        >
          {SUBMENUS_ARRAY.map((submenus, submenuIdx) => (
            <MenuList key={submenuIdx}>
              {submenus.map((submenu, idx) => {
                const { href } = submenu;
                return (
                  <li key={idx}>
                    <MenuItemLink
                      // disabled={submenuIdx === 0 ? true : false}
                      href={addLocalePrefix(href, locale)}
                      component={NextLink}
                      className={
                        isCurrentMenu(submenu.href, pathname, submenu.match)
                          ? 'Mui-active'
                          : undefined
                      }
                      style={{
                        textAlign: 'center',
                        marginTop: '8px',
                      }}
                    >
                      {submenu.title}
                    </MenuItemLink>
                  </li>
                );
              })}
            </MenuList>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function MenuItemLink(props: MenuItemProps & { href: string }) {
  const { children, href, ...restProps } = props;
  return (
    <MuiMenuItem component={NextLink} {...restProps} href={href}>
      {children}
    </MuiMenuItem>
  );
}
