'use client';

import { flatSx } from '@ocodelib/ui-common';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { CSSProperties, ReactNode, forwardRef } from 'react';

interface Props {
  sx?: SxProps;
  style?: CSSProperties;
  title?: ReactNode;
  onClose?: () => void;
  size?: 'small' | 'medium';
  children?: ReactNode;
}

export const CustomDialogTitle = forwardRef<HTMLElement, Props>((props, ref): JSX.Element => {
  const { sx, title, style, size = 'medium', onClose, children } = props;

  return (
    <Box
      display="flex"
      ref={ref}
      justifyContent="space-between"
      alignItems="center"
      className={clsx('CustomDialogTitle-root', {
        'CustomDialogTitle-medium': size === 'medium',
        'CustomDialogTitle-small': size === 'small',
      })}
      style={style}
      sx={flatSx(
        {
          pr: 1,
          pl: {
            xs: 2,
            md: 3,
          },
          '&.CustomDialogTitle-medium': {
            minHeight: 52,
          },
          '&.CustomDialogTitle-small': {
            minHeight: 48,
          },
        },
        sx,
      )}
    >
      {title && (
        <Box display="flex" alignItems="center">
          {typeof title === 'string' ? (
            <Typography
              variant={size === 'small' ? 'h6' : 'h5'}
              component="div"
              sx={{ color: 'inherit' }}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>
      )}

      {children}
      {onClose && (
        <IconButton onClick={onClose} size={size} sx={{ ml: 3 }} color="inherit">
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
});

CustomDialogTitle.displayName = 'CustomDialogTitle';
