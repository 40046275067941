'use client';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

DrawerHeader.displayName = 'DrawerHeader';
export default DrawerHeader;
