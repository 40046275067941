'use client';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import type { SxProps } from '@mui/material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

interface Props {
  sx?: SxProps;
  className?: string;
  text: string | undefined | null | (() => string | undefined | null);
}

export function ClipboardButton(props: Props) {
  const { sx, className, text } = props;
  const [msgVisible, setMsgVisible] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const handleClickCopyBtn = (event: React.MouseEvent) => {
    event.stopPropagation();
    const txt = typeof text === 'function' ? text() : text;
    if (txt && txt.length > 0) {
      copyToClipboard(txt);
      setMsgVisible(true);
    }
  };

  useEffect(() => {
    if (!msgVisible) return;
    setMsgVisible(true);
    const timer = setTimeout(() => {
      setMsgVisible(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [msgVisible]);

  return (
    <Box
      className={clsx('ClipboardButton-root', className)}
      sx={flatSx(
        {
          position: 'relative',
          display: 'inline-block',
        },
        sx,
      )}
    >
      <Tooltip title="copy to clipboard">
        <IconButton
          size="small"
          onClick={handleClickCopyBtn}
          disabled={msgVisible}
          sx={{
            opacity: msgVisible ? 0 : 1,
          }}
        >
          <ContentCopyIcon style={{ fontSize: '1rem' }} />
        </IconButton>
      </Tooltip>
      {msgVisible && (
        <span
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'auto',
            fontSize: '0.6rem',
            color: '#999',
            transform: 'rotate(-45deg)',
          }}
        >
          Copied
        </span>
      )}
    </Box>
  );
}
