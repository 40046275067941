import { mainLayoutConstants as LAYOUT } from '@/layout/MainLayout/main-layout-constants';
import { flatSx } from '@ocodelib/ui-common';
import type { SxProps } from '@mui/material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import * as nav from '@repo-ui/nav';
import clsx from 'clsx';
import NextLink from 'next/link';

const rootSx: SxProps = {
  display: 'flex',
  py: 0,
  pl: 0,
  pr: 1,
  '&.x_indent': {
    pl: 2,
  },
  '& em': {
    fontStyle: 'normal',
    color: 'yellow',
    mr: 0.5,
  },
  '&:hover': {
    backgroundColor: LAYOUT.sidemenu.bgColor,
  },

  '& .MuiListItemText-root': {
    color: LAYOUT.sidemenu.fgColor,
    fontWeight: 500,
    ml: 0,
    '& .MuiListItemText-primary': {
      fontSize: '0.85rem',
    },
    '& .MuiTypography-root': {
      color: 'inherit',
    },
  },

  '& .SideMenuItem-icon': {
    minWidth: 32,
  },
  '& .SideMenuItem-icon .MuiSvgIcon-root ': {
    color: LAYOUT.sidemenu.iconColor,
  },

  '&.Mui-active': {
    backgroundColor: LAYOUT.sidemenu.bgColor,
    borderLeft: `0px solid ${LAYOUT.sidemenu.bgColorHoverBack}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    mr: 2,
    '& .MuiListItemText-root': {
      color: LAYOUT.sidemenu.fgColorActive,
      fontWeight: 700,
      ml: 0,
    },
    '& .SideMenuItem-icon .SideMenuItem-icon': {
      color: LAYOUT.sidemenu.iconColorActive,
    },
  },
};

interface Props {
  sx?: SxProps;
  className?: string;
  menu: nav.IMenu;
  active: boolean;
  onLinkClick?: React.MouseEventHandler;
}

export default function SideMenuItem(props: Props) {
  const { sx, className, menu, active, onLinkClick } = props;
  const { icon, title, href } = menu;

  return (
    <ListItem
      sx={flatSx(rootSx, sx)}
      className={clsx('SideMenuItem-root', className, {
        'Mui-active': active,
      })}
    >
      <ListItemButton component={NextLink} href={href} onClick={onLinkClick}>
        {icon && <ListItemIcon className="SideMenuItem-icon">{icon}</ListItemIcon>}
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
}
