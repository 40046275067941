import { Box, ButtonBase, Container, IconButton, SxProps, Typography } from '@mui/material';
import type { OpenSourceCateKey } from '@ocode/domain';
import { flatSx } from '@ocodelib/ui-common';
import { isBlank } from '@ocodelib/util/string';
import GithubIcon from '@public/images/github.svg';
import InstargramIcon from '@public/images/instargram.svg';
import KakaoIcon from '@public/images/kakao.svg';
import NaverCafeIcon from '@public/images/naver_cafe.svg';
import type { IframeDialogProps } from '@repo-ui/dialogs/IframDialog.tsx';
import IframeDialog from '@repo-ui/dialogs/IframDialog.tsx';
import type { OpenSourceDialogProps } from '@repo-ui/dialogs/OpenSourceDialog';
import OpenSourceDialog from '@repo-ui/dialogs/OpenSourceDialog';
import { apiUrlOf } from '@repo-ui/util/urls';
import clsx from 'clsx';
import NextImage from 'next/image';
import { useState } from 'react';
import { toast } from 'react-toastify';

type DialogId = 'OpenSourceDialog' | 'IframeDialog';

interface Props {
  sx?: SxProps;
  className?: string;
}

export const clauseUrls = {
  /**
   * 약관 정보 URL
   */
  view: (clauseId: number): string => apiUrlOf(`/p/m/clause/${clauseId}.html`),

  // 이용약관
  viewUsage: (): string => apiUrlOf('/p/m/clause/1000004.html'),

  // 개인정보 처리방침
  viewPrivacy: (): string => apiUrlOf('/p/m/clause/1000000.html'),

  // 법적 고지
  viewLegalNotice: (): string => apiUrlOf('/p/m/clause/1000002.html'),

  /**
   * 약관 내용 보기 URL
   */
  substanceView: (substanceId: number): string =>
    apiUrlOf(`/p/m/clause-substance/${substanceId}.html`),
};

const handleClickSocialButton = (url: string) => {
  if (isBlank(url)) {
    toast.warn('준비중인 기능입니다', { position: 'top-center', autoClose: 1500 });
    return;
  }
};

export default function Footer(props: Props) {
  const { sx, className } = props;
  const [dialogId, setDialogId] = useState<DialogId>();
  const [openSourceDialogProps, setOpenSourceDialogProps] = useState<OpenSourceDialogProps>();
  const [iframeDialogProps, setIframeDialogProps] = useState<IframeDialogProps>();

  const handleDialogClose = () => {
    setDialogId(undefined);
    setIframeDialogProps(undefined);
    setOpenSourceDialogProps(undefined);
  };

  const openSourceDialog = (cate?: OpenSourceCateKey) => {
    setDialogId('OpenSourceDialog');
    setOpenSourceDialogProps({
      open: true,
      onClose: handleDialogClose,
    });
  };

  const openIframeDialog = (url: string, title?: string) => {
    setDialogId('IframeDialog');
    setIframeDialogProps({
      open: true,
      url,
      title,
      onClose: handleDialogClose,
    });
  };

  // 오픈 소스 클릭
  const handleClickOpenSource = (event: React.MouseEvent) => {
    event.preventDefault();
    openSourceDialog();
  };

  // 개인정보 처리방침 클릭
  const handleClickClausePrivacy = (event: React.MouseEvent) => {
    event.preventDefault();
    openIframeDialog(clauseUrls.viewPrivacy(), '개인정보 처리방침');
  };

  // 이용약관 클릭
  const handleClickClauseUsage = (event: React.MouseEvent) => {
    event.preventDefault();
    openIframeDialog(clauseUrls.viewUsage(), '이용약관');
  };

  // 법적고지 클릭
  const handleClickClauseLegalNotice = (event: React.MouseEvent) => {
    event.preventDefault();
    openIframeDialog(clauseUrls.viewLegalNotice(), '법적고지');
  };

  return (
    <Box className={clsx('Footer-root', className)} sx={flatSx({ backgroundColor: '#2d3033' }, sx)}>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: 1200,
        }}
      >
        <Box
          width="100%"
          sx={{
            py: 7,
            color: '#C6C8CA',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box className="Footer-LogoBox" sx={{ mr: 5 }}>
              <NextImage
                src="/_static/images/ocode_logo_white.png"
                alt=""
                width={168}
                height={48}
                priority
              />
            </Box>

            <Box mt={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'flex-start',
                  '& .MuiButtonBase-root > p': {
                    lineHeight: { xs: 2, md: 1 },
                  },
                  '& .MuiButtonBase-root:first-of-type': {
                    pr: 1,
                  },
                  '& .MuiButtonBase-root:last-of-type': {
                    pl: { xs: 0, md: 1 },
                  },
                  '& .MuiButtonBase-root:not(:last-of-type)': {
                    borderRight: { xs: 'none', md: '1px solid #C6C8CA' },
                  },
                  '& .MuiButtonBase-root:not(:first-of-type, :last-of-type)': {
                    px: { xs: 0, md: 1 },
                  },
                }}
              >
                <ButtonBase
                  disableRipple
                  sx={{ display: 'inline-block' }}
                  onClick={handleClickClausePrivacy}
                >
                  <Typography variant="body2">개인정보처리방침</Typography>
                </ButtonBase>
                <ButtonBase disableRipple onClick={handleClickClauseUsage}>
                  <Typography variant="body2">이용약관</Typography>
                </ButtonBase>
                <ButtonBase disableRipple onClick={handleClickClauseLegalNotice}>
                  <Typography variant="body2">법적고지</Typography>
                </ButtonBase>
                <ButtonBase disableRipple onClick={handleClickOpenSource}>
                  <Typography variant="body2">오픈소스 라이선스</Typography>
                </ButtonBase>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'flex-start', md: 'space-between' },
                  mt: { xs: 2, md: 0 },
                }}
              >
                <Box
                  className="Footer-Contact Info"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 0, md: 2 },
                  }}
                >
                  <Typography variant="body2" sx={{ mt: { xs: 0, md: 2 }, lineHeight: 2.5 }}>
                    대표이사 ○○○
                  </Typography>
                  <Typography variant="body2" sx={{ mt: { xs: 0, md: 2 }, lineHeight: 2.5 }}>
                    서울특별시 금천구
                  </Typography>
                  <Typography variant="body2" sx={{ mt: { xs: 0, md: 2 }, lineHeight: 2.5 }}>
                    사업자등록번호
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'center', md: 'space-between' },
              alignItems: { xs: 'flex-start', md: 'flex-end' },
              mt: { xs: 5, md: 1 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'flex-start', md: 'flex-end' },
                gap: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <IconButton onClick={() => handleClickSocialButton('')}>
                  <KakaoIcon />
                </IconButton>
                <IconButton onClick={() => handleClickSocialButton('')}>
                  <InstargramIcon />
                </IconButton>
                <IconButton onClick={() => handleClickSocialButton('')}>
                  <GithubIcon />
                </IconButton>
                <IconButton onClick={() => handleClickSocialButton('')}>
                  <NaverCafeIcon />
                </IconButton>
              </Box>

              <Typography variant="body2" color="#76787A">
                copyright(c)All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Box>
        {dialogId === 'IframeDialog' && iframeDialogProps && (
          <IframeDialog {...iframeDialogProps} />
        )}
        {dialogId === 'OpenSourceDialog' && openSourceDialogProps && (
          <OpenSourceDialog {...openSourceDialogProps} />
        )}
      </Container>
    </Box>
  );
}
