'use client';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMainLayoutStore } from '@repo-ui/store/useMainLayoutStore';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { mainNavManager as navManager } from '../../main-layout-side-menus';
import DrawerHeader from '../DrawerHeader';
import SideMenuItem from './SideMenuItem';
import SideSectionMenu from './SideSectionMenu';
import { SidebarAutoOpenCheck } from './SidebarAutoOpenCheck';
import { rootSx } from './style';

export default function Sidebar() {
  const pathname = usePathname();
  const theme = useTheme();
  const mainLayoutStore = useMainLayoutStore((state) => state);
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'));
  const [prepared, setPrepared] = useState(false);
  const pathkey = pathname;

  useEffect(() => {
    setPrepared(true);
  }, []);

  const handleClickLink = () => {
    if (smOrDown) {
      mainLayoutStore.setSidebarOpened(false);
    }
  };

  if (!prepared) return null;

  return (
    <Box component="nav" className="Sidebar-root" sx={rootSx}>
      <SidebarAutoOpenCheck />
      <DrawerHeader sx={{ justifyContent: 'flex-start' }}>
        <Box
          className="Sidebar-logoWrapper"
          sx={{
            color: '#fffA',
            '& a:visited': {
              color: '#fffA',
            },
            '& a:hover': {
              color: '#ffD',
            },
          }}
        >
          <Box
            className="Sidebar-drawerHeader"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& img': {
                maxWidth: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <NextLink href="/" className="Sidebar-logoLink">
              {/* <Box component="img" src="/_static/images/logo_white.svg" className="Sidebar-logoImage" /> */}
              <Typography variant="h5">OCODE</Typography>
            </NextLink>
          </Box>
          <IconButton
            onClick={() => {
              mainLayoutStore.setSidebarOpened(false);
            }}
            size="large"
          >
            <ChevronLeftIcon fontSize="small" className="Sidebar-collapsedIcon" htmlColor="#fff" />
          </IconButton>
        </Box>
      </DrawerHeader>
      <List disablePadding className="Sidebar-list" sx={{ overflowX: 'hidden' }}>
        {navManager.menuItems.map((item) => {
          if (item.type === 'menu') {
            return (
              <SideMenuItem
                key={item.id}
                menu={item}
                onLinkClick={handleClickLink}
                active={navManager.isMenuPathMatched(item, pathkey)}
              />
            );
          }
          if (item.type === 'divider') {
            return (
              <Box
                key={item.id}
                className="Sidebar-divider"
                sx={{ height: '0px', borderTop: '1px solid rgba(255,255,255,0.1)' }}
              />
            );
          }
          if (item.type === 'label') {
            return (
              <ListItem key={item.id} className="Sidebar-label" dense>
                {item.icon}
                <ListItemText>{item.title}</ListItemText>
              </ListItem>
            );
          }

          if (item.type === 'section') {
            const section = item;
            return (
              <SideSectionMenu
                key={item.id}
                navManager={navManager}
                section={section}
                currentHref={pathkey}
                expanded={mainLayoutStore.expandedSectionIds.includes(section.sectionId)}
                onClickLink={handleClickLink}
                onClickSection={() => {
                  mainLayoutStore.toggleExpandSection(section.sectionId);
                }}
              />
            );
          }
          return <div key={-1}>{JSON.stringify(item)}</div>;
        })}
      </List>
    </Box>
  );
}
